export const getUser = /* GraphQL */ `
    query GetUser($id: ID!) {
        getUser(id: $id) {
            id
            username
            name {
                first
                last
            }
            address {
                line1
                line2
                city
                county
                state
                zip
            }
            schools {
                items {
                    id
                    school {
                        id
                        name {
                            legal
                            popular
                        }
                        district {
                            id
                            name {
                                legal
                                popular
                            }
                        }
                        zone {
                            id
                            name
                            counties
                        }
                    }
                }
            }
            ensembleIds
            instrumentIds
            zoneIds
            avatar {
                size
                name
                src
            }
            createdAt
            updatedAt
            districtUsersId
        }
    }
`;
export const getUserByUsername = /* GraphQL */ `
    query GetUserByUsername(
        $username: String!
        $sortDirection: ModelSortDirection
        $filter: ModelUserFilterInput
        $limit: Int
        $nextToken: String
    ) {
        getUserByUsername(
            username: $username
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
        ) {
            items {
                id
                username
                name {
                    first
                    last
                }
                address {
                    line1
                    line2
                    city
                    county
                    state
                    zip
                }
                schools {
                    items {
                        id,
                        school {
                            id
                            name {
                                legal
                                popular
                            }
                            district {
                                id
                                name {
                                    legal
                                    popular
                                }
                            }
                            zone {
                                id
                                name
                                counties
                            }
                        }
                    }
                }
                ensembleIds
                instrumentIds
                zoneIds
                avatar {
                    size
                    name
                    src
                }
                createdAt
                updatedAt
                districtUsersId
            }
            nextToken
        }
    }
`;

export const listDistricts = /* GraphQL */ `
    query ListDistricts(
        $id: ID
        $filter: ModelDistrictFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listDistricts(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name {
                    legal
                    popular
                }
                zone {
                    id
                    name
                    counties
                }
                schools {
                    items {
                        id
                        name {
                            legal
                            popular
                        }
                    }
                }
                state {
                    enabled
                }
            }
            nextToken
        }
    }
`;

export const listSchools = /* GraphQL */ `
    query ListSchools(
        $id: ID
        $filter: ModelSchoolFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listSchools(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name {
                    legal
                    popular
                }
                zone {
                    id
                    name
                    counties
                }
                state {
                    enabled
                }
            }
            nextToken
        }
    }
`;

export const listEnsembles = /* GraphQL */ `
    query ListEnsembles(
        $id: ID
        $filter: ModelEnsembleFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listEnsembles(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                state {
                    enabled
                }
            }
            nextToken
        }
    }
`;

export const listInstruments = /* GraphQL */ `
    query ListInstruments(
        $id: ID
        $filter: ModelInstrumentFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listInstruments(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                state {
                    enabled
                }
            }
            nextToken
        }
    }
`;


export const listZones = /* GraphQL */ `
    query ListZones(
        $id: ID
        $filter: ModelZoneFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listZones(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                name
                counties
                state {
                    enabled
                }
            }
            nextToken
        }
    }
`;
