<template>
  <page-layout ref="layout">
    <template #breadcrumbs>
      <b-breadcrumb-item text="Management" />
      <b-breadcrumb-item text="Users" :to="{ name: 'management-users' }" />
      <b-breadcrumb-item :text="`${user.name.first} ${user.name.last}`" active />
    </template>

    <template #actions="{ state }">
      <can do="update" on="management-user">
        <b-button v-if="state.editing" v-b-tooltip="'Update'" variant="transparent" size="sm" @click="updateUser">
          <font-awesome-icon icon="fa-solid fa-cloud-arrow-up" />
        </b-button>
      </can>
    </template>

    <template #dropdown-options="{ state }">
      <can do="update" on="management-user">
        <b-dropdown-item @click="state.editing = !state.editing">
          <feather-icon icon="EditIcon"/>
          <span class="align-middle ml-50">Edit</span>
        </b-dropdown-item>
        <b-dropdown-item @click="updateUserState(user)">
          <font-awesome-icon :icon="['fas', user.state.enabled ? 'toggle-off' : 'toggle-on']"></font-awesome-icon>
          <span class="align-middle ml-50">{{ user.state.enabled ? 'Disable' : 'Enable' }}</span>
        </b-dropdown-item>
      </can>
      <can do="delete" on="management-user">
        <b-dropdown-item @click="$refs.layout.confirmDelete(user, deleteUser, cascadeConfirmDeleteOptions)">
          <feather-icon icon="TrashIcon" />
          <span class="align-middle ml-50">Delete</span>
        </b-dropdown-item>
      </can>

      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item v-if="user.state.status === 'CONFIRMED'" @click="resetUserPassword">
        <font-awesome-icon icon="fas fa-lock"></font-awesome-icon>
        <span class="align-middle ml-50">Reset Password</span>
      </b-dropdown-item>
      <b-dropdown-item v-if="user.state.status === 'FORCE_CHANGE_PASSWORD'" @click="resendUserInvitation"> <!--!user.state.email_verified && -->
        <font-awesome-icon icon="fas fa-lock"></font-awesome-icon>
        <span class="align-middle ml-50">Resend Invitation</span>
      </b-dropdown-item>
      <b-dropdown-item v-if="!user.state.email_verified && user.state.status === 'CONFIRMED'" @click="verifyUserEmail">
        <feather-icon icon="CheckCircleIcon" />
        <span class="align-middle ml-50">Verify Email</span>
      </b-dropdown-item>


      <b-dropdown-item @click="signOutUser">
        <feather-icon icon="LogOutIcon"/>
        <span class="align-middle ml-50">Sign Out</span>
      </b-dropdown-item>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-item @click="refresh">
        <feather-icon icon="RotateCwIcon"/>
        <span class="align-middle ml-50">Refresh</span>
      </b-dropdown-item>
    </template>

    <template #content="{ state }">
      <validation-observer ref="observer" tag="div">
        <form ref="form">
          <b-row>
            <b-col cols="8">
              <b-card>
                <b-row>
                  <b-col align-self="start" cols="auto">
                    <b-avatar v-if="hasCustomAvatar(user.avatar)" v-b-modal.avatar-modal variant="primary" size="6em" badge-variant="white" :disabled="!state.editing">
                      <b-img fluid fluid-grow :src="user.avatar.src" :alt="user.avatar.name"></b-img>
                      <template #badge>
                        <b-icon :icon="enabledIcon(user.state.enabled)" :variant="enabledIconVariant(user.state.enabled)"></b-icon>
                      </template>
                    </b-avatar>
                    <b-avatar v-else v-b-modal.avatar-modal variant="primary" size="6em" badge-variant="white" :disabled="!state.editing">
                      <font-awesome-icon :icon="icon" size="3x"></font-awesome-icon>
                      <template #badge>
                        <b-icon :icon="enabledIcon(user.state.enabled)" :variant="enabledIconVariant(user.state.enabled)"></b-icon>
                      </template>
                    </b-avatar>
                    <avatar-modal v-if="state.editing"
                                  title="User Avatar"
                                  :avatar="user.avatar"
                                  @update-avatar="updateAvatar"/>
                  </b-col>
                  <b-col align-self="center">
                    <validation-provider v-slot="validationContext" vid="first-name" name="First Name" rules="required">
                      <b-form-group label="First Name" label-for="first-name-input" :invalid-feedback="validationContext.errors[0]">
                        <b-input v-model="user.name.first" :disabled="!state.editing"></b-input>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                  <b-col align-self="center">
                    <validation-provider v-slot="validationContext" vid="last-name" name="Last Name" rules="required">
                      <b-form-group label="Last Name" label-for="last-name-input" :invalid-feedback="validationContext.errors[0]">
                        <b-input v-model="user.name.last" :disabled="!state.editing"></b-input>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
            <b-col>
              <b-card>
                <validation-provider v-slot="validationContext" vid="group" name="Groups" rules="required">
                  <b-form-group label="Groups" label-for="group-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                    <v-select id="group-input"
                              v-model="user.groups" multiple
                              label="GroupName"
                              :disabled="!state.editing"
                              :loading="options.groups.loading"
                              :options="options.groups.items"
                              :reduce="val => val.GroupName"
                              :select-on-tab="true"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              class="w-100"
                              @option:selecting="addCognitoUserToGroup"
                              @option:deselecting="removeCognitoUserFromGroup"/>
                  </b-form-group>
                </validation-provider>
              </b-card>
            </b-col>
          </b-row>

          <!-- Account -->
          <b-card title="Account">
            <b-row>
              <b-col>
                <validation-provider v-slot="validationContext" vid="username" name="Username" rules="required">
                  <b-form-group label="Username" label-for="line1-input" :invalid-feedback="validationContext.errors[0]">
                    <b-input-group class="input-group-merge disabled" :class="[validationContext.errors.length > 0 ? 'is-invalid' : null]">
                      <b-form-input id="line1-input"
                                    v-model="user.username"
                                    :disabled="true"
                                    :state="getValidationState(validationContext)" />
                      <b-input-group-append is-text>
                        <b-icon v-if="user.state.status === 'CONFIRMED'"
                                v-b-tooltip.hover.v-success
                                title="Confirmed"
                                icon="shield-fill-check"
                                variant="primary" />
                        <b-icon v-else
                                v-b-tooltip.hover.v-danger
                                title="Not Confirmed"
                                icon="shield-fill-exclamation"
                                variant="danger" />
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <validation-provider v-slot="validationContext" vid="email" name="Email" rules="required">
                  <b-form-group label="Email Address" label-for="email-input" :invalid-feedback="validationContext.errors[0]">
                    <b-input-group class="input-group-merge" :class="[validationContext.errors.length > 0 ? 'is-invalid' : null, !state.editing ? 'disabled' : null]">
                      <b-form-input id="email-input"
                                    v-model="user.email"
                                    :disabled="!state.editing"
                                    :state="getValidationState(validationContext)" />
                      <b-input-group-append is-text>
                        <b-icon v-if="user.state.email_verified"
                                v-b-tooltip.hover.v-success
                                title="Verified"
                                icon="patch-check-fill"
                                variant="primary" />
                        <b-icon v-else
                                v-b-tooltip.hover.v-danger
                                title="Unverified"
                                icon="patch-exclamation-fill"
                                variant="danger" />

                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <validation-provider v-slot="validationContext" vid="phone" name="Phone" rules="required">
                  <b-form-group label="Phone Number" label-for="phone-input" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="phone-input"
                                  v-model="user.phone"
                                  v-mask="'+1 (###) ###-####'"
                                  :disabled="!state.editing"
                                  :state="getValidationState(validationContext)"/>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-card>
          <b-card v-if="isTeacher || isChair" title="Address">
            <b-row>
              <b-col>
                <validation-provider v-slot="validationContext" vid="line1" name="Line 1" rules="required">
                  <b-form-group label="Line 1" label-for="line1-input" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="line1-input" v-model="user.address.line1" :disabled="!state.editing" :state="getValidationState(validationContext)"></b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <validation-provider v-slot="validationContext" vid="line2" name="Line 2" rules="">
                  <b-form-group label="Line 2" label-for="line2-input" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="line2-input" v-model="user.address.line2" :disabled="!state.editing" :state="getValidationState(validationContext)"></b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <validation-provider v-slot="validationContext" vid="city" name="City" :rules="rules.city">
                  <b-form-group label="City" label-for="city-input" :invalid-feedback="validationContext.errors[0]">
                    <b-form-input id="city-input" v-model="user.address.city" :disabled="!state.editing" :state="getValidationState(validationContext)"></b-form-input>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <validation-provider v-slot="validationContext" vid="county" name="County" rules="required">
                  <b-form-group label="County" label-for="county-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
                    <v-select id="county-input"
                              v-model="user.address.county"
                              :options="counties"
                              :loading="options.counties.loading"
                              :disabled="!state.editing"
                              :select-on-tab="true"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              class="w-100"/>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="auto">
                <validation-provider v-slot="validationContext" vid="state" name="State" :rules="rules.state">
                  <b-form-group label="State" label-for="state-input" :invalid-feedback="validationContext.errors[0]">
                    <state-input id="state-input" v-model="user.address.state" :disabled="!state.editing" :validation-state="getValidationState(validationContext)"/>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="auto">
                <validation-provider v-slot="validationContext" vid="zip-code" name="Zip Code" :rules="rules.zip">
                  <b-form-group label="Zip Code" label-for="zip-code-input" :invalid-feedback="validationContext.errors[0]">
                    <zip-input id="zip-code-input" v-model="user.address.zip" :disabled="!state.editing" :state="getValidationState(validationContext)"/>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </b-card>

          <!-- Group Permissions -->
          <template v-if="isTeacher || isChair || isCommittee || isZoneRep">
						<b-card title="Group Permissions">
							<b-row v-if="isTeacher">
								<b-col cols="auto">
									<b-form-group label="Type" label-for="type-input">
										<b-checkbox v-model="options.isPublic" :disabled="!state.editing" button button-variant="primary" > <!--@change="clearDistrictAndSchoolSelection"-->
											{{ options.isPublic ? 'Public' : 'Non-Public' }}
										</b-checkbox>
									</b-form-group>
								</b-col>
								<b-col v-if="options.isPublic">
									<validation-provider v-slot="validationContext" vid="district" name="District" rules="required">
										<b-form-group label="District" label-for="district-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
											<v-select id="district-input"
																v-model="user.district"
																label="name"
																:disabled="!state.editing"
																:loading="isEditing && options.districts.loading"
																:options="districts"
																:reduce="val => val"
																:filter="districtSearch"
																:select-on-tab="true"
																:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
																class="w-100"
																@input="setDistrictClearSchoolSelection"
											>
												<template #option="{ name }">
													<h6 class="mb-0">{{ name.legal }}</h6>
													<small>{{ name.popular }}</small>
												</template>
												<template #selected-option="{ name }">
													{{ name.legal }}
												</template>
											</v-select>
										</b-form-group>
									</validation-provider>
								</b-col>
								<b-col>
									<validation-provider v-slot="validationContext" vid="school" name="Schools" rules="required">
										<b-form-group label="Schools" label-for="school-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
											<v-select id="school-input"
																v-model="user.schools.items" multiple
																label="id"
																:disabled="!state.editing"
																:loading="isEditing && options.schools.loading"
																:options="schools"
																:reduce="val => val"
																:filter="schoolSearch"
																:select-on-tab="true"
																:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
																class="w-100"
																@option:selecting="createUserSchool"
																@option:deselected="deleteUserSchool">
												<template #option="{ school }">
													<h6 class="mb-0">{{ school.name.legal }}</h6>
													<small>{{ school.name.popular }}</small>
												</template>
												<template #selected-option="{ school }">
													{{ school.name.legal }}
												</template>
											</v-select>
										</b-form-group>
									</validation-provider>
								</b-col>
							</b-row>
							<b-row v-if="isChair">
								<b-col>
									<validation-provider v-slot="validationContext" vid="school" name="Ensembles" rules="required">
										<b-form-group label="Ensembles" label-for="school-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
											<v-select id="school-input"
																v-model="user.ensembleIds" multiple
																label="name"
																:disabled="!state.editing"
																:loading="isEditing && options.ensembles.loading"
																:options="ensembles"
																:reduce="val => val.id"
																:select-on-tab="true"
																:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
																class="w-100"
																@input="patchUser({ id: user.id, ensembleIds: user.ensembleIds } )"
											/>
										</b-form-group>
									</validation-provider>
								</b-col>
							</b-row>
							<b-row v-if="isCommittee">
								<b-col>
									<validation-provider v-slot="validationContext" vid="instruments" name="Instruments" rules="required">
										<b-form-group label="Instruments" label-for="school-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
											<v-select id="school-input"
																v-model="user.instrumentIds" multiple
																label="name"
																:disabled="!state.editing"
																:loading="isEditing && options.instruments.loading"
																:options="instruments"
																:reduce="val => val.id"
																:select-on-tab="true"
																:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
																class="w-100"
																@input="patchUser({ id: user.id, instrumentIds: user.instrumentIds } )"
											/>
										</b-form-group>
									</validation-provider>
								</b-col>
							</b-row>
							<b-row v-if="isZoneRep">
								<b-col>
									<validation-provider v-slot="validationContext" vid="zones" name="Zones" rules="required">
										<b-form-group label="Zones" label-for="school-input" :invalid-feedback="validationContext.errors[0]" :state="getValidationState(validationContext)">
											<v-select id="school-input"
																v-model="user.zoneIds" multiple
																label="name"
																:disabled="!state.editing"
																:loading="isEditing && options.zones.loading"
																:options="zones"
																:reduce="val => val.id"
																:select-on-tab="true"
																:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
																class="w-100"
																@input="patchUser({ id: user.id, zoneIds: user.zoneIds } )"
											/>
										</b-form-group>
									</validation-provider>
								</b-col>
							</b-row>
						</b-card>
          </template>
        </form>
      </validation-observer>
    </template>

    <template #debug>
      <b-row>
        <b-col cols="12">
          <debug title="User">
            {{ user }} <br/>
          </debug>

          <debug>
            {{ awsPhoneNumber }}
          </debug>
        </b-col>
<!--        <b-col cols="6">
          <debug title="Districts" collapsed>{{ options.districts }}</debug>
        </b-col>
        <b-col cols="6">
          <debug title="Schools" collapsed>{{ options.schools }}</debug>
        </b-col>-->
      </b-row>
    </template>
  </page-layout>



</template>

<script>
import PageLayout from '@/components/PageLayout.vue';
import AvatarModal from '@/components/Avatar/AvatarModal.vue';
import SchoolModal from '@/views/management/schools/SchoolModal.vue';
import vSelect from 'vue-select'
import avatar from '@/mixins/avatar.mixin'
import role from '@/mixins/role.mixin'
import status from '@/mixins/status.mixin'
import print from '@/mixins/print.mixin'
import notify from '@/mixins/notify.mixin'
import {mask} from 'vue-the-mask'
import Fuse from 'fuse.js';
import {uuid} from 'vue-uuid';
import {rules} from '@/data/validation-rules';
import StateInput from '@/components/StateInput.vue';
import ZipInput from '@/components/ZipInput.vue';
import { API, graphqlOperation } from 'aws-amplify';
import { createUserSchools, deleteUserSchools, updateUser } from '@/graphql/mutations';
import { getUserByUsername, listDistricts, listSchools, listEnsembles, listInstruments, listZones } from '@/graphql/queries/user';
import {
  adminAddUserToGroup,
  adminDisableUser,
  adminEnableUser,
  adminGetUser, adminListGroups,
  adminListGroupsForUser, adminRemoveUserFromGroup,
  adminResendUserInvitation,
  adminResetUserPassword,
  adminUpdateUserAttributes, adminUserSignOut
} from '@/scripts/aws';
import { cascadeDeleteUser, cascadeConfirmDeleteOptions} from '@/graphql/cascade/user';

export default {
  components: {
    ZipInput,
    StateInput,
    SchoolModal,
    PageLayout,
    AvatarModal,
    vSelect
  },
  directives: { mask },
  mixins: [ avatar, role, status, print, notify ],
  props: {
    id: {
      type: String,
      required: false,
      default: null
    },
    username: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      user: { },
      options: {
        groups: {
          items: [],
          loading: true
        },
        districts: {
          items: [],
          loading: true
        },
        schools: {
          items: [],
          loading: true
        },
        ensembles: {
          items: [],
          loading: true
        },
        instruments: {
            items: [],
            loading: true
        },
        zones: {
          items: [],
          loading: true
        },
        counties: {
          items: [],
          loading: true
        },
        isPublic: true
      },
      rules: {
        city: rules.address.city,
        state: rules.address.state,
        zip: rules.address.zip
      },
      icon: 'fas fa-user',
      cascadeConfirmDeleteOptions,
    }
  },
  computed: {
    awsPhoneNumber() {
      return this.user.phone?.replaceAll(/[^+\d]+/g, '')
    },
    districts() {
      if(this.isEditing && this.options.districts.loading) {
        this.listDistricts()
        return []
      }
      return this.options.districts.items
    },
    schools() {
      if(this.isEditing && !this.options.isPublic && this.options.schools.items.length === 0) {
        this.listSchools()
        return []
      }
      if(this.options.isPublic && !this.options.districts.loading) {
        return this.user.district ? this.options.districts.items.find(district => district.id === this.user.district.id).schools.items : []
      }
      return this.options.schools.items
    },
    ensembles() {
      if(this.options.ensembles.loading) {
        this.listEnsembles()
        return []
      }
      return this.options.ensembles.items
    },
    instruments() {
        if(this.options.instruments.loading) {
            this.listInstruments()
            return []
        }
        return this.options.instruments.items
    },
    zones() {
      if(this.options.zones.loading) {
        this.listZones()
        return []
      }
      return this.options.zones.items
    },
    isChair() {
      return this.user.groups.includes('NYSSMA_Chair')
    },
    isCommittee() {
      return this.user.groups.includes('NYSSMA_Committee')
    },
    isTeacher() {
      return this.user.groups.includes('Teacher')
    },
    isZoneRep() {
      return this.user.groups.includes('ZoneRep')
    },

    isEditing() {
      return this.$refs.layout.state.editing
    },
    counties() {
      if(this.options.counties.loading) {
        this.listZones()
        return []
      }
      return this.options.counties.items
    }
  },
  async mounted() {
    await this.listGroups();
    await this.getUser();
  },
  methods: {
    async listGroups() {
      const cognitoGroupsResponse = await adminListGroups()
      this.options.groups.items = cognitoGroupsResponse.Groups
      this.options.groups.loading = false
    },
    async listDistricts(nextToken, pagedDistricts) {
      this.options.districts.loading = true
      this.options.schools.loading = true

      const districts = pagedDistricts || []
      const response = await API.graphql(graphqlOperation(listDistricts, { limit: 500, nextToken: nextToken }));

      districts.push(...response.data.listDistricts.items.filter(district => district.state.enabled))

      if(response.data.listDistricts.nextToken) {
        await this.listDistricts(response.data.listDistricts.nextToken, districts)
      }
      else {
        districts.forEach(district => {
          if(district.zone) {
            district.zone.counties.sort((a, b) => a.localeCompare(b))
          }
          district.schools.items = district.schools.items.map(school => ({
            id: this.user.schools.items.find(userSchool => userSchool.school.id === school.id)?.id || uuid.v4(),
            school: {
              id: school.id,
              name: {
                legal: school.name.legal,
                popular: school.name.popular
              }
            }
          })).sort((a, b) => a.school.name.legal.localeCompare(b.school.name.legal))
        })
        this.options.districts.items = districts.sort((a, b) => a.name.legal.localeCompare(b.name.legal))
        this.options.districts.loading = false
        this.options.schools.loading = false
      }
    },
    async listSchools(nextToken, pagedSchools) {
      this.options.schools.loading = true

      const schools = pagedSchools || []
      const response = await API.graphql(graphqlOperation(listSchools, { limit: 500, nextToken: nextToken, filter: { districtSchoolsId: { attributeExists: false }} }));
      this.options.schools.items = response.data.listSchools.items.filter(school => school.state.enabled);

      schools.push(...response.data.listSchools.items.filter(school => school.state.enabled).map( school => ({
        id: this.user.schools.items.find(userSchool => userSchool.school.id === school.id)?.id || uuid.v4(),
        school: {
          id: school.id,
          name: {
            legal: school.name.legal,
            popular: school.name.popular
          },
          zone: {
            counties: school.zone?.counties?.sort((a, b) => a.localeCompare(b)) || []
          }
        }
      })))

      if(response.data.listSchools.nextToken) {
        await this.listSchools(response.data.listSchools.nextToken, schools)
      }
      else {
        this.options.schools.items = schools.sort((a, b) => a.school.name.legal.localeCompare(b.school.name.legal))
        this.options.schools.loading = false
      }
    },
    async listEnsembles(nextToken, pagedEnsembles) {
      const ensembles = pagedEnsembles || []
      const response = await API.graphql(graphqlOperation(listEnsembles, { limit: 100, nextToken: nextToken }));

      ensembles.push(...response.data.listEnsembles.items.filter(district => district.state.enabled))

      if(response.data.listEnsembles.nextToken) {
        await this.listEnsembles(response.data.listEnsembles.nextToken, ensembles)
      }
      else {
        this.options.ensembles.items = ensembles.sort((a, b) => a.name.localeCompare(b.name));
        this.options.ensembles.loading = false
      }
    },
    async listInstruments(nextToken, pagedItems) {
        const items = pagedItems || []
        const response = await API.graphql(graphqlOperation(listInstruments, { limit: 100, nextToken: nextToken }));

        items.push(...response.data.listInstruments.items.filter(item => item.state.enabled))

        if(response.data.listInstruments.nextToken) {
            await this.listInstruments(response.data.listInstruments.nextToken, items)
        }
        else {
            this.options.instruments.items = items.sort((a, b) => a.name.localeCompare(b.name));
            this.options.instruments.loading = false
        }
    },
    async listZones(nextToken, pagedItems) {
      const items = pagedItems || []
      const response = await API.graphql(graphqlOperation(listZones, { limit: 100, nextToken: nextToken }));

      items.push(...response.data.listZones.items.filter(district => district.state.enabled))

      if(response.data.listZones.nextToken) {
        await this.listZones(response.data.listZones.nextToken, items)
      }
      else {
        this.options.zones.items = items.sort((a, b) => a.name.localeCompare(b.name, 'en', { numeric: true }));
        this.options.zones.loading = false

        const counties = []
        items.forEach(item => { counties.push(...item.counties) })

        this.options.counties.items = counties.sort((a, b) => a.localeCompare(b));
        this.options.counties.loading = false
      }
    },
    async getUser() {
      const cognitoUserResponse = await adminGetUser(this.username)
      const cognitoGroupsResponse = await adminListGroupsForUser(this.username)
      const appSyncUserResponse = await API.graphql(graphqlOperation(getUserByUsername, { username: this.username }));
      const cognitoUser = cognitoUserResponse
      const appSyncUser = appSyncUserResponse.data.getUserByUsername.items[0]
      const cognitoGroups = cognitoGroupsResponse.Groups

      if(appSyncUser?.schools?.items?.length) {
        appSyncUser.schools.items.forEach(item => {
          if(item.school.zone) {
            item.school.zone.counties.sort((a, b) => a.localeCompare(b))
          }
        })
      }

      this.user = {
        id: appSyncUser.id,
        username: appSyncUser.username,
        email: cognitoUser.UserAttributes.find(attribute => attribute.Name === 'email')?.Value,
        phone: cognitoUser.UserAttributes.find(attribute => attribute.Name === 'phone_number')?.Value,
        name: appSyncUser.name,
        address: appSyncUser.address,
        avatar: appSyncUser.avatar,
        district: appSyncUser.schools.items[0]?.school?.district,
        school: appSyncUser.school,
        schools: appSyncUser.schools,
        ensembleIds: appSyncUser.ensembleIds,
        instrumentIds: appSyncUser.instrumentIds,
        zoneIds: appSyncUser.zoneIds,
        group: cognitoGroups.map(group => group.GroupName)[0],
        groups: cognitoGroups.map(group => group.GroupName),
        state: {
          enabled: cognitoUser.Enabled,
          status: cognitoUser.UserStatus,
          email_verified: !!cognitoUser.UserAttributes.find(attribute => attribute.Name === 'email_verified'),
          initial: {
            email: cognitoUser.UserAttributes.find(attribute => attribute.Name === 'email')?.Value,
            phone: cognitoUser.UserAttributes.find(attribute => attribute.Name === 'phone_number')?.Value,
            group: cognitoGroups.map(group => group.GroupName)[0],
            schools: appSyncUser.schools.items
          }
        }
      }

      if(this.user.district === null) {
        this.options.isPublic = false
      }

      this.$refs.layout.state.loading = false
    },

    async updateUserState() {
      if(this.user.state.enabled) {
        await this.disableUser(this.user)
      }
      else {
        await this.enableUser(this.user)
      }
    },
    async enableUser(user) {
      await adminEnableUser(user.username).then(() => {
        user.state.enabled = true
        this.notify({ title: 'Success', text: 'User was successfully enabled', icon: this.icon, variant: 'success' });
      })
      .catch(() => {
        this.notify({ title: 'Error', text: 'User failed to update', icon: this.icon, variant: 'danger' });
      })
    },
    async disableUser(user) {
      await adminDisableUser(user.username).then(() => {
        user.state.enabled = false
        this.notify({ title: 'Success', text: 'User was successfully disabled', icon: this.icon, variant: 'success' });
      })
      .catch(() => {
        this.notify({ title: 'Error', text: 'User failed to update', icon: this.icon, variant: 'danger' });
      })
    },

    async resetUserPassword() {
      await adminResetUserPassword(this.user.username).then((r) => {
        this.notify({ title: 'Success', text: 'User Password was successfully reset', icon: this.icon, variant: 'success' });
      })
      .catch((e) => {
        console.error(e)
        this.notify({ title: 'Error', text: 'Failed to reset user password', icon: this.icon, variant: 'danger' });
      })
    },
    async resendUserInvitation() {
      await adminResendUserInvitation(this.user.username).then((r) => {
        this.notify({ title: 'Success', text: 'User Invitation was successfully reset', icon: this.icon, variant: 'success' });
      })
      .catch((e) => {
        console.error(e)
        this.notify({ title: 'Error', text: 'Failed to resend user invitation', icon: this.icon, variant: 'danger' });
      })
    },
    async verifyUserEmail() {
      await adminUpdateUserAttributes(this.user.username, [{ Name: 'email_verified', Value: 'true' }]).then((r) => {
        this.user.state.email_verified = true
        this.notify({ title: 'Success', text: 'User Email was verified reset', icon: this.icon, variant: 'success' });
      })
      .catch((e) => {
        console.error(e)
        this.notify({ title: 'Error', text: 'Failed to resend user invitation', icon: this.icon, variant: 'danger' });
      })
    },
    async signOutUser() {
      await adminUserSignOut(this.user.username).then((r) => {
        this.notify({ title: 'Success', text: 'User was successfully signed out', icon: this.icon, variant: 'success' });
      })
      .catch((e) => {
        console.error(e)
        this.notify({ title: 'Error', text: 'Failed to sign out user', icon: this.icon, variant: 'danger' });
      })
    },

    async deleteUser(user, swalCallback) {
      try {
        await this.cascadeDeleteUser(user.id, swalCallback);
        await this.notify({ title: 'Success', text: 'User was successfully deleted', icon: this.icon, variant: 'success' });
        await this.$router.push({ name: 'management-users' })
      }
      catch(error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'User failed to delete', icon: this.icon, variant: 'danger'});
        throw error //for swal
      }
    },
    cascadeDeleteUser,

    async patchUser(input) {
      try {
        await API.graphql(graphqlOperation(updateUser, { input: input } ));
        this.notify({ title: 'Success', text: 'User was successfully updated', icon: 'fas fa-code-branch', variant: 'success' });
      }
      catch(error) {
        console.error(error)
        this.notify({ title: 'Error', text: 'User failed to update', icon: 'fas fa-code-branch', variant: 'danger'});
      }
    },

    async updateUser() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        await this.patchUser({
          id: this.user.id,
          name: this.user.name,
          avatar: this.user.avatar,
          address: this.user.address,
        })

        await this.updateCognitoUser()
        //await this.updateCognitoUserGroup()
      }
      else {
        this.notify({ title: 'Warning', text: 'User failed to update. Missing required fields.', icon: 'fas fa-server', variant: 'warning'});
      }
    },


    async updateUserSchools() {
      if(this.user.schools.every(school => this.user.state.initial.schools.map(initial => initial.id).includes(school.id))) {
        //return //user.schools matches initial schools list, do nothing...
        //user.schools matches initial schools list, do nothing...
      }

      const create = []
      this.user.schools.forEach(school => {
        if(!this.user.state.initial.schools.map(initial => initial.id).includes(school.id)) {
          create.push(school.id)
        }
      })

      const remove = []
      this.user.state.initial.schools.forEach(initial => {
        if(!this.user.schools.map(school => school.id).includes(initial.id)) {
          remove.push(initial.id)
        }
      })

      if(this.user.schools !== this.user.state.initial.group) {
        await adminAddUserToGroup(this.user.username, this.user.group)
        if(this.user.state.initial.group) {
          await adminRemoveUserFromGroup(this.user.username, this.user.state.initial.group)
        }
      }
    },

    async createUserSchool(option) {
      const input = {
        id: option.id,
        userID: this.user.id,
        schoolID: option.school.id
      }
      await API.graphql(graphqlOperation(createUserSchools, { input: input }));
      this.notify({ title: 'Success', text: 'User School was successfully added', icon: this.icon, variant: 'success' });
    },
    async deleteUserSchool(option) {
      await API.graphql(graphqlOperation(deleteUserSchools, { input: { id: option.id } }));
      this.notify({ title: 'Success', text: 'User School was successfully removed', icon: this.icon, variant: 'success' });
    },

    setDistrictClearSchoolSelection(value) {
      this.user.district = value
      if(this.user.schools.items.length) {
        const userSchoolsForDeletion = this.user.schools.items.map(async userSchool => {
          await API.graphql(graphqlOperation(deleteUserSchools, { input: { id: userSchool.id } }));
        })
        Promise.all(userSchoolsForDeletion).then(() => {
          this.user.schools.items = []
          this.notify({ title: 'Success', text: 'User Schools were successfully removed', icon: this.icon, variant: 'success' });
        }).catch(e => { console.error(e) })
      }
    },

    async updateCognitoUser() {
      const attributes = []
      let emailChanged = false;

      if(this.user.email !== this.user.state.initial.email) {
        emailChanged = true
        attributes.push({ Name: 'email', Value: this.user.email })
        attributes.push({ Name: 'email_verified', Value: 'true' })
      }
      if(this.awsPhoneNumber !== this.user.state.initial.phone) { //use awsPhoneNumber instead of user.phone because the input v-mask alters the value
        attributes.push({ Name: 'phone_number', Value: this.awsPhoneNumber })
      }

      if(attributes.length) {
        await adminUpdateUserAttributes(this.user.username, attributes).then(() => {
          if(emailChanged) { this.user.state.email_verified = false }
          this.notify({ title: 'Success', text: 'Cognito User was successfully updated', icon: 'fas fa-code-branch', variant: 'success' });
        })
        .catch(e => {
          console.error(e)
          this.notify({ title: 'Error', text: 'Cognito User failed to update', icon: 'fas fa-code-branch', variant: 'danger'});
        })
      }
    },
    /*async updateCognitoUserGroup() {
      if(this.user.group !== this.user.state.initial.group) {
        await adminAddUserToGroup(this.user.username, this.user.group)
        if(this.user.state.initial.group !== null) {
          await adminRemoveUserFromGroup(this.user.username, this.user.state.initial.group)
        }
      }
    },*/
    async addCognitoUserToGroup(group) {
      try {
        await adminAddUserToGroup(this.user.username, group.GroupName)
        this.notify({ title: 'Success', text: 'Cognito User was successfully updated', icon: 'fas fa-code-branch', variant: 'success' });
      }
      catch(e) {
        console.error(e)
        this.notify({ title: 'Error', text: 'Cognito User failed to update', icon: 'fas fa-code-branch', variant: 'danger'});
      }
    },
    async removeCognitoUserFromGroup(group) {
      try {
        await adminRemoveUserFromGroup(this.user.username, group.GroupName)
        this.notify({ title: 'Success', text: 'Cognito User was successfully updated', icon: 'fas fa-code-branch', variant: 'success' });
      }
      catch(e) {
        console.error(e)
        this.notify({ title: 'Error', text: 'Cognito User failed to update', icon: 'fas fa-code-branch', variant: 'danger'});
      }
    },

    // eslint-disable-next-line no-shadow
    async updateAvatar(avatar) {
      this.user.avatar = avatar;
      await this.patchUser({ id: this.user.id, avatar: this.user.avatar })
    },
    async refresh() {
      this.$refs.layout.state.loading = true
      await this.getUser()
      this.$refs.layout.state.loading = false
    },
    getValidationState({ dirty, validated, valid = null }) {
      if(this.$refs.layout.state.editing) {
        return dirty || validated ? valid : null;
      }
      return null;
    },
    districtSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['name.legal', 'name.popular'],
        shouldSort: true,
      })
      return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
    },
    schoolSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['school.name.legal', 'school.name.popular'],
        shouldSort: true,
      })
      return search.length ? fuse.search(search).map(({ item }) => item) : fuse.list
    },
    includesGroup(group) {
      return this.user.groups.includes(group)
    },
  },
}
</script>

<style lang="scss">
  @import '~@core/scss/vue/libs/vue-select.scss';

  .per-page-selector {
    min-width: 90px;
  }

  .invoice-filter-select {
    min-width: 190px;

    ::v-deep .vs__selected-options {
      flex-wrap: nowrap;
    }

    ::v-deep .vs__selected {
      width: 100px;
    }
  }
</style>
