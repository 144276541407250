/* eslint-disable no-await-in-loop */
import {API, graphqlOperation} from 'aws-amplify';
import {batchProcessWithRetry, SwalData} from '@/graphql/cascade/batch';
import {adminDeleteUser} from '@/scripts/aws'

export const cascadeConfirmDeleteOptions = {
    confirm: {
        text: `
          <p class='mb-0'>Deleting this User may have unwanted side effects.</p>
          <p class='font-small-3 text-danger'>
            Applications, Students, and Schools that are currently associated with this user will have their association removed.<br/>
            This may effect applications, selections, acceptance, as well as reports.
          </p>
          <strong>Be very careful when performing this action.</strong>
        `,
        shouldParse: true
    },
    confirmed: { text: 'User was successfully deleted.' }
}

export async function cascadeDeleteUser(userId, swalCallback) {
    try {
        /** Get User - We Need this for the username **/
        const user = await getUser(userId, swalCallback)
        if(user) {
            /** Delete User-School records associated to this User **/
            const userSchools = await listUserSchools(userId, swalCallback)
            await batchProcessWithRetry(userSchools, async (userSchool) => {
                //console.log('deleteUserSchoolsMutation', { input: { id: userSchool.id }})
                await API.graphql(graphqlOperation(deleteUserSchoolsMutation, { input: { id: userSchool.id }} ));
            }, { title: 'Deleting User Schools', callback: swalCallback })

            await new Promise(resolve => setTimeout(resolve, 1000));

            /** Update Application records associated to this User **/
            const applications = await listApplications(userId, swalCallback)
            await batchProcessWithRetry(applications, async (application) => {
                //console.log('updateApplicationMutation', { input: { id: application.id, teacherID: null }})
                await API.graphql(graphqlOperation(updateApplicationMutation, { input: { id: application.id, teacherID: null }}));
            }, { title: 'Updating Applications', callback: swalCallback })

            /** Delete User **/
            await deleteUser(userId, swalCallback)
            await deleteUserCognito(user.username, swalCallback)
        }
    }
    catch(e) {
        console.error(e)
    }
}

async function getUser(userId, swalCallback) {
    const title = 'Getting User'
    swalCallback(new SwalData(title, 0, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
    const response = await API.graphql(graphqlOperation(getUserQuery, { id: userId }));
    swalCallback(new SwalData(title, 1, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
    return response?.data?.getUser
}

async function deleteUser(userId, swalCallback) {
    const title = 'Deleting User'
    swalCallback(new SwalData(title, 0, 1))
    await new Promise(resolve => setTimeout(resolve, 500));

    //console.log('deleteUser', { id: userId })
    await API.graphql(graphqlOperation(deleteUserMutation, {input: { id: userId }}));

    swalCallback(new SwalData(title, 1, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
}

async function deleteUserCognito(username, swalCallback) {
    const title = 'Deleting Cognito User'
    swalCallback(new SwalData(title, 0, 1))
    await new Promise(resolve => setTimeout(resolve, 500));

    //console.log('deleteUserCognito', { username: username })
    await adminDeleteUser(username)

    swalCallback(new SwalData(title, 1, 1))
    await new Promise(resolve => setTimeout(resolve, 500));
}

async function listUserSchools(userId, swalCallback) {
    const items = []
    let nextToken = null;
    do {
        try {
            const input = { limit: 250, nextToken: nextToken, filter: { userID: { eq: userId } }}
            const response = await API.graphql(graphqlOperation(listUserSchoolsQuery, input));
            items.push(...response.data.listUserSchools.items)
            nextToken = response.data.listUserSchools.nextToken;
            swalCallback(new SwalData('Loading User Schools', items.length, items.length))
        }
        catch (error) {
            console.error(error);
            break;
        }
    }
    while (nextToken);
    await new Promise(resolve => setTimeout(resolve, 1000));
    return items
}

async function listApplications(userId, swalCallback) {
    const items = []
    let nextToken = null;
    do {
        try {
            const input = { limit: 250, nextToken: nextToken, filter: { teacherID: { eq: userId } } }
            const response = await API.graphql(graphqlOperation(listApplicationsQuery, input));
            items.push(...response.data.listApplications.items)
            nextToken = response.data.listApplications.nextToken;
            swalCallback(new SwalData('Loading Applications', items.length, items.length))
        }
        catch (error) {
            console.error(error);
            break;
        }
    }
    while (nextToken);
    return items
}


/** Queries & Mutations **/
const getUserQuery = /* GraphQL */ `
    query GetUser($id: ID!) {
        getUser(id: $id) {
            id
            username
        }
    }
`;

const listUserSchoolsQuery = /* GraphQL */ `
    query ListUserSchools(
        $filter: ModelUserSchoolsFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listUserSchools(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
            }
            nextToken
        }
    }
`;

const listApplicationsQuery = /* GraphQL */ `
    query ListApplications(
        $id: ID
        $filter: ModelApplicationFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listApplications(
            id: $id
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                teacherID
            }
            nextToken
        }
    }
`;

const deleteUserSchoolsMutation = /* GraphQL */ `
    mutation DeleteUserSchools(
        $input: DeleteUserSchoolsInput!
        $condition: ModelUserSchoolsConditionInput
    ) {
        deleteUserSchools(input: $input, condition: $condition) {
            id
        }
    }
`;

const updateApplicationMutation = /* GraphQL */ `
    mutation UpdateApplication(
        $input: UpdateApplicationInput!
        $condition: ModelApplicationConditionInput
    ) {
        updateApplication(input: $input, condition: $condition) {
            id
            teacherID
        }
    }
`;

const deleteUserMutation = /* GraphQL */ `
    mutation DeleteUser(
        $input: DeleteUserInput!
        $condition: ModelUserConditionInput
    ) {
        deleteUser(input: $input, condition: $condition) {
            id
        }
    }
`;
